<template>
     <h4>Case Properties</h4>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">    
                    <label class="col-md-12 control-label text-left">Quote Number</label>
                    <div class="col-md-12">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="form-group">    
                <label class="col-md-12 control-label text-left required">Quantity<span class="required">(Required)</span></label>
                <div class="col-md-12">
                    <input type="text" class="form-control" value="1">
                </div>
                </div>
                <div class="form-group">    
                <label class="col-md-12 control-label text-left required">Item Number<span class="required">(Required)</span></label>
                <div class="col-md-12">
                    <input type="text" class="form-control" value="1">
                </div>
                </div>
            </div> 
            <div class="col-md-4">
                <div class="form-group">    
                    <label class="col-md-12 control-label text-left required">Item Description<span class="required">(Required)</span></label>
                    <div class="col-md-12">
                    <input type="text" class="form-control" value="1">
                    </div>
                </div>
                <div class="form-group">    
                    <label class="col-md-12 control-label text-left required">Unit Price<span class="required">(Required)</span></label>
                    <div class="col-md-12">
                    <input type="text" class="form-control" value="1">
                    </div>
                </div>
                <div class="form-group">    
                    <label class="col-md-12 control-label text-left required">Total Price<span class="required">(Required)</span></label>
                    <div class="col-md-12">
                    <input type="text" class="form-control" value="1">
                    </div>
                </div>
            </div>  
            <div class="col-md-4">
                <div class="form-group">    
                <label class="col-md-12 control-label text-left">Agent Comment</label>
                <div class="col-md-12">
                    <textarea class="form-control" rows="3"></textarea>
                </div>
                </div> 
                <div class="form-group">    
                <label class="col-md-12 control-label text-left">Wrap Code</label>
                <div class="col-md-12">
                    <select class="form-control"><option value="--Select Value--">--Select Value--</option></select>
                </div>
                </div>
            </div>  
            <div class="col-md-12">
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
            </div>
</template>

<script>
  var displaySave = true;
  export default {
  name: 'InteractionPropertyTabComponents',
   components: {
      
  },
  props: ['displaySaveButton'],
  data: function(){
      return{
       
      }
  },
  computed: {
    showSaveButton: function () {
        if (typeof(this.displaySaveButton) != "undefined"){
            return false;
        }else{
            return true;
        }
    }
  }
 

}

</script>