<template>
    <div class="form-group" v-if="!createnotecontent && !agentemailcontent">
        <button class="btn btn-primary bg-aqua-active btn-info margin-r-5">
          <i class="fa fa-share-alt"></i>
          Request Social Review
        </button> 
        <button class="btn btn-primary bg-aqua-active btn-info margin-r-5">
          <i class="fa fa-user-plus"></i>
          Request Refferal
        </button> 
        <button class="btn btn-primary bg-aqua-active btn-info margin-r-5">
          <i class="fa fa-plus"></i>
          Add to List
        </button> 
      
    </div>
  <div v-if="!createnotecontent && !agentemailcontent">
     
      <div  class="row">
          <div class="col-md-12">
              <div class="box">
                  <div class="box-header with-border">
                      <h3 class="box-title head-title-padding">Project Details</h3>
                  </div>
                  <div class="box-body">
                              <div class="message-box-header">
                                  <div class="row">
                                    <div class="col-md-8">
                                       <div class="box box-info">
                                          <div class="box-header with-border">
                                            <i class="fa fa-fw fa-bars"></i>
                                            <h3 class="box-title head-title-padding">Project Summary</h3>
                                          </div>
                                          <div class="box-body">   
                                             <div>
                                                <strong>Jhon Doe</strong> <br>
                                                <address>
                                                  1876 N Ranch , <br>
                                                  IL 66354<br>
                                                </address>
                                              </div>
                                              <div class="description">
                                                <strong>Project description -</strong> <br>
                                                <p>
                                                  Insulation is a general term used to describe material that creates barriers for transmission of electricity, heat, moisture, shock or sound between insulated surfaces of adjacent bodies.
                                                 </p> 
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="box box-info">
                                          <div class="box-header with-border">
                                            <i class="fa fa-exchange"></i>
                                            <h3 class="box-title head-title-padding">Interaction Details</h3>
                                          </div>
                                          <div class="box-body">   
                                              <div><b>Interaction ID : </b>4645646</div>
                                              <div><b> Segment / Topic : </b> 8a. Completed - Accepted</div>
                                              <div><b> Resolved By : </b></div>
                                              <div><b> Approved By : </b>&nbsp;</div>
                                              <div><b> Assigned To : </b>Adam</div>
                                              <div><b> Interaction Status : </b><small class="bg-green-active label">Resolved</small></div>
                                              <div><b> Interaction Type : </b></div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                  
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="container">                      
                                        <div class="box box-info">
                                          <div class="box-header with-border">
                                            <i class="fa fa-clock-o"></i>
                                            <h3 class="box-title head-title-padding">Project Timeline</h3>
                                          </div>
                                          <div class="box-body">   
                                            <div class="row">
                                            <div class="col">
                                                <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
                                                    <div class="timeline-step">
                                                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                                            <div class="inner-circle request-bg"><i class="fa icon-c icon-hand-click"></i></div>
                                                            <p class="h6 mt-3 mb-1">2023-04-01 10.00</p>
                                                            <p class="h6 text-muted mb-0 mb-lg-0">Request Submitted</p>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-step">
                                                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                                            <div class="inner-circle visit-sche-bg"><i class="fa icon-c icon-visit-schedule"></i></div>
                                                            <p class="h6 mt-3 mb-1">2023-04-02 10.00</p>
                                                            <p class="h6 text-muted mb-0 mb-lg-0">Visit Scheduled</p>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-step">
                                                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                                            <div class="inner-circle visit-comp-bg"><i class="fa icon-c icon-visit-complete"></i></div>
                                                            <p class="h6 mt-3 mb-1">2023-04-03 12.00</p>
                                                            <p class="h6 text-muted mb-0 mb-lg-0">Visit Completed</p>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-step">
                                                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                                            <div class="inner-circle quote-sent-bg"><i class="fa icon-c icon-quote-sent"></i></div>
                                                            <p class="h6 mt-3 mb-1">2023-04-03 02.00</p>
                                                            <p class="h6 text-muted mb-0 mb-lg-0">Quote Sent</p>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-step mb-0">
                                                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                                            <div class="inner-circle quote-appr-bg"><i class="fa icon-c icon-quote-approved"></i></div>
                                                            <p class="h6 mt-3 mb-1">2023-04-04 10.00</p>
                                                            <p class="h6 text-muted mb-0 mb-lg-0">Quote Accepted</p>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-step mb-0">
                                                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                                            <div class="inner-circle install-sche-bg"><i class="fa icon-c icon-install-schedule"></i></div>
                                                            <p class="h6 mt-3 mb-1">2023-04-04 10.00</p>
                                                            <p class="h6 text-muted mb-0 mb-lg-0">Install Scheduled</p>
                                                        </div>
                                                    </div>
                                                    <div class="timeline-step mb-0">
                                                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                                            <div class="inner-circle install-comp-bg"><i class="fa icon-c icon-install-complete"></i></div>
                                                            <p class="h6 mt-3 mb-1">2023-04-04 10.00</p>
                                                            <p class="h6 text-muted mb-0 mb-lg-0">Install Completed</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                          </div>
                                        </div>
                                        
                                    </div>
                                    </div>
                                  </div>
                                  
                         </div>   
                  </div>
              </div>
          </div>
      </div>
  </div>

  <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
          <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
  </template>
  
  <script>

  import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
  import GoleyincInstallationNotice from '../components/GoleyincInstallationNotice.vue';
  import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';

  import Multiselect from '@vueform/multiselect'
  
      export default {
        name: 'EmailAddressSubjectResponseComponents',
        props: ['tabOption'],
        components: {
          Multiselect,
          InteractionPropertyTab,
          GoleyincInstallationNotice,
          AgentEmailContent,
          CreateNoteContent
        },
        mounted(){
          this.emitter.on("show-view-details", Data => {
            this[Data.displaySegment] = true;
            this.viewmodecontent = false;
              });
              this.emitter.on("hide-view-details", Data => {
                  this[Data.displaySegment] = false;
                  this.viewmodecontent = true;
              });
        },
        data: function(){
            return{
              showBCC : true,
              date: new Date(),
              timezone: '',
              projectcompleted : false,
              createnotecontent : false,
              agentemailcontent : false,
            }
        },
          methods: {
              showagentemailcontent1(segment){
              this.agentemailcontent = true;
              this.createnotecontent = false;
              },
              showcreatenotecontent1(segment){
              this.createnotecontent = true;
              this.agentemailcontent = false;
              }

          }
      }
  </script>