<template>
    <div class="row">
        <div class="col-md-12 form-horizontal">
            <div class="form-group" v-if="!createnotecontent && !agentemailcontent">
                <div class="col-md-12" >
                    
                    <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to James',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="showagentemailcontent1('agentemailcontent')">
                        <i class="fa fa-envelope"></i>
                        Agent email
                    </button> 
                    <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to James',theme: {placement: 'bottom', }}"  v-on:click="showcreatenotecontent1('createnotecontent')">
                        <i class="fa fa-pencil-square-o"></i>
                        Note
                    </button>
                    <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Payment Collected',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="acceptQuote = true">
                        <i class="fa fa-dollar"></i>
                        Payment Collected
                    </button> 
                    <button class="btn btn-danger margin-r-5" v-tooltip="{text: 'Reject Quote',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="rejectQuote = true;">
                        <i class="fa fa-file-text-o"></i>
                        Reject Quote
                    </button> 
                    <button class="btn btn-primary" type="button"><i class="fa fa-save"></i> Save Draft </button>
                    <button class="btn btn-link" type="button">Show Standard Response Form </button>
                    
                </div>
            </div>
            <div>
                <div class="nav-tabs-custom">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-fw fa-envelope-o"></i> <span>Rejection Detail</span></a>
                        </li>
                       
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'Interaction Properties',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span></a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane show active" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                            <!--Standard Response start-->
                            <div class="row">
                                
                                <div class="col-md-8 form-horizontal">
                                    <p>These properties are only used when rejecting a quote.</p>
                                    <div class="form-group">
                                        <label class="col-3 control-label text-left required">Rejection Reason <span class="required">(Required)</span></label>
                                        <div class="col-9">
                                            <select class="form-control">
                                              <option>Payment related issue</option>
                                            </select>
                                        </div>
                                    </div>
                                

                                    <div class="form-group">
                                        <label class="col-3 control-label text-left">Rejection Description (Optional)</label>
                                        <div class="col-9">
                                            <textarea class="form-control"></textarea>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                                <!--Standard Response End-->
                        </div>
                    
                        <div class="tab-pane fade" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                        <GoleyincVisitNotice></GoleyincVisitNotice>
                        </div>
                
                        <div class="tab-pane show" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                            <div class="row form-vertical">
                                <div class="col-md-4">
                                    <h4>Case Properties</h4>
                                    <div class="form-group">
                                        <label class="col-md-12 control-label text-left required">Contact Person Name <span class="required">(Required)</span></label>
                                        <div class="col-12"><input type="text" class="form-control"></div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-md-12 control-label text-left required">Email Address <span class="required">(Required)</span></label>
                                        <div class="col-12"><input type="text" class="form-control"></div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-md-12 control-label text-left">Phone Number</label>
                                        <div class="col-12"><input type="text" class="form-control"></div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-md-12 control-label text-left">Short Description</label>
                                        <div class="col-12"><textarea class="form-control"></textarea></div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                
                                    <div class="form-group">
                                        <label class="col-md-12 control-label text-left">Office Location</label>
                                        <div class="col-12"><input type="text" class="form-control"></div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-md-12 control-label text-left">Type of Service</label>
                                        <div class="col-12"><input type="text" class="form-control"></div>
                                    </div>
                                
                                    <div class="form-group">
                                        <label class="col-md-12 control-label text-left">Detail</label>
                                        <div class="col-12"><textarea class="form-control" rows="3"></textarea></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  class="row" v-if="!createnotecontent && !agentemailcontent">
                <div class="col-md-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title head-title-padding">Original Question</h3>
                        </div>
                        <div class="box-body">
                        
                                    <div class="message-box-header">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="subject-heading"><b>Subject : </b> Feedback provided </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="user-container"><span class="user"><img src="/img/agent.png"><b>Agent</b></span><span>John Doe</span></div>
                                                <div class="message-info"><b>From : </b>john.doe@example.com<br><b>To : </b>alek.packard@1to1service.com<br><b>CC : </b>justin.time@1to1service.com<br></div>
                                                <div class="int-det-box pull-right">
                                                    <div><b>Interaction ID : </b>4645646</div>
                                                    <div><b> Segment / Topic : </b>4. Waiting for Payment</div>
                                                    <div><b> Resolved By : </b></div>
                                                    <div><b> Approved By : </b>&nbsp;</div>
                                                    <div><b> Assigned To : </b>Adam</div>
                                                    <div><b> Interaction Status : </b><small class="bg-yellow-disabled label">Pending</small></div>
                                                    <div><b> Interaction Type : </b></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-md-12 mtop-15"><b><i class="fa fa-paperclip attachment-icon"></i></b><a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image1.jpg (123KB)</span></a> <a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image2.jpg (30kb)</span></a> <a href="" class="attachments"><i class="fa fa-file-pdf-o"></i><span class="filename"> File1.pdf (40KB)</span></a></div>
                                        <div class="col-md-12 mtop-15"><div class="message-box overflow-box"><div class="message-box-body"><div class="clear"></div><div><p> Hello,<br> I'm looking for insulation. </p></div></div></div></div>
                                
                                </div>
                                
                        </div>
                    </div>
                </div>
            </div>

            <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
            <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
        <!--<GoleyQuoteQuestionDetailsTab></GoleyQuoteQuestionDetailsTab>-->
        </div>
    </div>
 </template>
 
 <script>
 import ActionModeButtons from '../components/ActionModeButtons.vue';
 import EmailAddressSubjectResponse from '../components/EmailAddressSubjectResponse.vue';
 import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
 
 import QuoteButtons from '../components/QuoteButtons.vue';
 import GoleyOpenQuoteButtons from '../components/GoleyOpenQuoteButtons.vue';
 
 import QuoteResponseBody from '../components/QuoteResponseBody.vue';
 import GoleyQuoteQuestionDetailsTab from '../components/GoleyQuoteQuestionDetailsTab.vue';
 import ContactInformation from '../components/ContactInformation.vue';
 
 import DraftBodyTabs from '../components/DraftBodyTabs.vue';
 
 import HistoryTabContent from '../components/HistoryTabContent.vue';
 import AgentEmailContent from '../components/AgentEmailContent.vue';
  import CreateNoteContent from '../components/CreateNoteContent.vue';
 
 
 export default {
   name: 'InboxMessageDetailComponents',
    components: {
       ActionModeButtons,
       EmailAddressSubjectResponse,
       VariableAndStockResponses,
       QuoteButtons,
       QuoteResponseBody,
       GoleyQuoteQuestionDetailsTab,
       ContactInformation,
       DraftBodyTabs,
       HistoryTabContent,
       GoleyOpenQuoteButtons,
       AgentEmailContent,
       CreateNoteContent
   },
   data: function(){
       return{
        quote:true,
        message:false,
        showDetails :false,
        acceptQuote:false,
        rejectQuote:false,
        createnotecontent : false,
        agentemailcontent : false,
       }
   },
   mounted() { 
      this.emitter.on("show-view-details", Data => {
              this[Data.displaySegment] = true;
              this.viewmodecontent = false;
      });
      this.emitter.on("hide-view-details", Data => {
        this[Data.displaySegment] = false;
        this.viewmodecontent = true;
      });
     this.emitter.on("show-message-details", Data => {
       this.showDetails = Data.showDetails;
       if(Data.displayType != 'quote'){
           this.quote = false;
           this.message = true;
       }else{
           this.quote = true;
           this.message = false;
       }
     });
   },
    methods: {
        showagentemailcontent1(segment){
          this.agentemailcontent = true;
          this.createnotecontent = false;
        },
        showcreatenotecontent1(segment){
          this.createnotecontent = true;
          this.agentemailcontent = false;
        }

    }
  
 }
 </script>