<template>

       <div class="col-md-12">
            <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Accept Quote',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="acceptQuote = true">
               <i class="fa fa-file-text-o"></i>
               Accept Quote
           </button> 
           <button class="btn btn-danger margin-r-5" v-tooltip="{text: 'Reject Quote',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="rejectQuote = true;">
               <i class="fa fa-file-text-o"></i>
               Reject Quote
           </button> 
           <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to John Doe',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="show('agentemailcontent')">
               <i class="fa fa-envelope"></i>
               Agent email
           </button> 
           <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to John Doe',theme: {placement: 'bottom', }}"  v-on:click="show('createnotecontent')">
           <i class="fa fa-pencil-square-o"></i>
           Note
           </button>
          
           <button class="btn btn-link" type="button">Show Standard Response Form </button>
         
       </div>
       <!--<StatusAudit v-if = 'statusaudit'></StatusAudit>
       <FullCaseHistory v-if = "fullcasehistory"></FullCaseHistory>
       <div class="col-md-12">    
           <div class="nav-tabs-custom mtop-15">
               <ul class="nav nav-tabs" id="myTab" role="tablist">
   
                   <li class="nav-item" role="presentation">
                       <a class="nav-link active" v-bind:id="'tab2'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_2_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'This interaction has file attachments.',theme: {placement: 'bottom', }}" ><i class="fa fa-paperclip"></i> <span>Details & Attachments</span><span class="new-inside"></span></a>
                   </li>
                   <li class="nav-item" role="presentation">
                       <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property values set for this interactions.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span><span class="new-inside"></span></a>
                   </li>
               
               </ul>
               <div class="tab-content" id="myTabContent">
               
                   <div class="tab-pane show active" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                     <div class="row">
                       <div class="col-md-12">
                           <div class="pull-left"> 
                               <h4>Details</h4>
                               <div class="row">
                               <div class="col-md-6"> <b>Interaction Id</b>:114576</div>
                               <div class="col-md-6"> <b>Type</b>:Ticket</div>
                               <div class="col-md-6"> <b>From</b>:John.doe@example.com</div>
                               <div class="col-md-6"> <b>Segment</b>:Sales Segment</div>
                               <div class="col-md-6"><b>Topic</b>: Open Quotes</div>
                               <div class="col-md-6"><b>Status</b>: Queued</div>
                               </div>
                           </div>
                       </div>
                      
                        <div class="col-md-12 mtop-15"><b><i class="fa fa-paperclip attachment-icon"></i></b><a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image1.jpg (123KB)</span></a> <a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image2.jpg (30kb)</span></a> <a href="" class="attachments"><i class="fa fa-file-pdf-o"></i><span class="filename"> File1.pdf (40KB)</span></a></div>
                        
                     </div>
   
                   </div>
                 
                   <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                   <QuoteInteractionPropertyTab></QuoteInteractionPropertyTab>
                   </div>
   
               </div>
           </div>
       </div>
       <div class="col-md-12">
           <div class="nav-tabs-custom">
                <ul class="nav nav-tabs">
                    <li class="nav-item"><a class="nav-link active" type="button" id="preview-tab" data-bs-toggle="tab" data-bs-target="#qoutepreview" role="tab" aria-controls="previewbody"  aria-selected="false">Quote Body<br></a></li>
                </ul>
                <div class="tab-content">
                
                    <div class="tab-pane active" id="qoutepreview">
                    <QuotePreview></QuotePreview>
                    </div>
                </div>
            </div>
       </div>-->

   </template>
   <style src="@vueform/toggle/themes/default.css"></style>
   <script>
   import QuoteInteractionPropertyTab from '../components/QuoteInteractionPropertyTab.vue';
   import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
   
   
   import StatusAudit from '../components/StatusAudit.vue';
   import FullCaseHistory from '../components/FullCaseHistory.vue';
   import Toggle from '@vueform/toggle'
   import QuotePreview from '../components/QuotePreview.vue';
   
   import Multiselect from '@vueform/multiselect'
   
       export default {
         name: 'Expanded Row',
         props: {showGetThis:Boolean,showAnswer:Boolean},
         components: {
           Multiselect,
           QuoteInteractionPropertyTab,
           DetailsandattachmentsTabContent,
           StatusAudit,
           FullCaseHistory,
           Toggle,
           QuotePreview
           
         },
         mounted(){
              if(this.showGetThis == 0){
                  this.showget = false;
              }else{
                 this.showget = true;
              }
   
               if(this.showAnswer == 0){
                  this.showanswer = false;
              }else{
                 this.showanswer = true;
              }
           },
         data: function(){
             return{
               showBCC : true,
               date: new Date(),
               timezone: '',
               showexpanded1: false,
               toggleMore : true,
               statusaudit : false,
               fullcasehistory : false,
               palintext: true,
               showget : Boolean(this.showGetThis),
               showanswer : Boolean(this.showGetThis),
               showconfirmdelete:false,
               acceptQuote:false,
               rejectQuote:false
             }
         },
          methods: {
               show(segment){
                 this.emitter.emit("show-view-details",{displaySegment:segment});
               }
               
           }
       }
   </script>
   
   