<template>
    <div class="form-group" v-if="!createnotecontent && !agentemailcontent">
      <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to James',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="showagentemailcontent1('agentemailcontent')">
          <i class="fa fa-send"></i>
          Send Final Project Details
      </button> 
      
  </div>
  <div v-if="!createnotecontent && !agentemailcontent">
      <div class="nav-tabs-custom">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                  <a class="nav-link active" v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-fw fa-envelope-o"></i> <span>Details</span></a>
              </li>
          
              <li class="nav-item" role="presentation">
                  <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'Interaction Properties',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Property</span></a>
              </li>
          </ul>
          <div class="tab-content" id="myTabContent">
              <div class="tab-pane show active" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                  <!--Standard Response start-->
                  <div class="row">
                      <div class="col-md-8 form-horizontal">
                      <div class="form-group">
                          <label class="col-3 control-label text-left">To </label>
                          <div class="col-9">
                              <select class="form-control">
                              <option>Jacob.smith@1to1service.com</option>
                              </select>
                          </div>
                      </div>
                      <div class="form-group">
                          <label class="col-3 control-label text-left required">Comments <span class="required">(Required)</span></label>
                          <div class="col-9">
                              <textarea class="form-control"></textarea>
                          </div>
                      </div>
                       
                      </div>
                      <div class="col-md-4">
                        <div class="form-group"><div class="col-md-12"><label class="drop-box"> Drop files here or click to upload <input type="file" style="display:none;" multiple=""></label><div>(Maximum 10 MB upoad at one time.)</div><div class="imageuploadify"> ngRepeat: file in files </div></div></div>
                         
                      </div>
                  </div>
                      <!--Standard Response End-->
              </div>
          
              <div class="tab-pane fade" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
              <GoleyincInstallationNotice></GoleyincInstallationNotice>
              </div>
      
              <div class="tab-pane show" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                  <div class="row form-vertical">
                      <div class="col-md-4">
                          <h4>Case Properties</h4>
                          <div class="form-group">
                              <label class="col-md-12 control-label text-left required">Contact Person Name <span class="required">(Required)</span></label>
                              <div class="col-12"><input type="text" class="form-control"></div>
                          </div>
                          <div class="form-group">
                              <label class="col-md-12 control-label text-left required">Email Address <span class="required">(Required)</span></label>
                              <div class="col-12"><input type="text" class="form-control"></div>
                          </div>
                          <div class="form-group">
                              <label class="col-md-12 control-label text-left">Phone Number</label>
                              <div class="col-12"><input type="text" class="form-control"></div>
                          </div>
                          <div class="form-group">
                              <label class="col-md-12 control-label text-left">Short Description</label>
                              <div class="col-12"><textarea class="form-control"></textarea></div>
                          </div>
                      </div>
                      <div class="col-md-4">
                      
                          <div class="form-group">
                              <label class="col-md-12 control-label text-left">Office Location</label>
                              <div class="col-12"><input type="text" class="form-control"></div>
                          </div>
                          <div class="form-group">
                              <label class="col-md-12 control-label text-left">Type of Service</label>
                              <div class="col-12"><input type="text" class="form-control"></div>
                          </div>
                      
                          <div class="form-group">
                              <label class="col-md-12 control-label text-left">Detail</label>
                              <div class="col-12"><textarea class="form-control" rows="3"></textarea></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div  class="row">
          <div class="col-md-12">
              <div class="box">
                  <div class="box-header with-border">
                      <h3 class="box-title head-title-padding">Original Question</h3>
                  </div>
                  <div class="box-body">
                  
                              <div class="message-box-header">
                                  <div class="row">
                                      <div class="col-md-12">
                                          <div class="subject-heading"><b>Subject : </b> Feedback provided </div>
                                      </div>
                                      <div class="col-md-12">
                                          <div class="user-container"><span class="user"><img src="/img/agent.png"><b>Agent</b></span><span>John Doe</span></div>
                                          <div class="message-info"><b>From : </b>john.doe@example.com<br><b>To : </b>alek.packard@1to1service.com<br><b>CC : </b>justin.time@1to1service.com<br></div>
                                          <div class="int-det-box pull-right">
                                              <div><b>Interaction ID : </b>4645646</div>
                                              <div><b> Segment / Topic : </b> 7. Completed Installs</div>
                                              <div><b> Resolved By : </b></div>
                                              <div><b> Approved By : </b>&nbsp;</div>
                                              <div><b> Assigned To : </b>Adam</div>
                                              <div><b> Interaction Status : </b><small class="bg-yellow-disabled label">Pending</small></div>
                                              <div><b> Interaction Type : </b></div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="clearfix"></div>
                                  <div class="col-md-12 mtop-15"><b><i class="fa fa-paperclip attachment-icon"></i></b><a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image1.jpg (123KB)</span></a> <a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image2.jpg (30kb)</span></a> <a href="" class="attachments"><i class="fa fa-file-pdf-o"></i><span class="filename"> File1.pdf (40KB)</span></a></div>
                                  <div class="col-md-12 mtop-15"><div class="message-box overflow-box"><div class="message-box-body"><div class="clear"></div><div><p> Hello,<br> I'm looking for insulation. </p></div></div></div></div>
                          </div>   
                  </div>
              </div>
          </div>
      </div>
  </div>

  <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
          <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
  </template>
  
  <script>

  import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
  import GoleyincInstallationNotice from '../components/GoleyincInstallationNotice.vue';
  import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';

  import Multiselect from '@vueform/multiselect'
  
      export default {
        name: 'EmailAddressSubjectResponseComponents',
        props: ['tabOption'],
        components: {
          Multiselect,
          InteractionPropertyTab,
          GoleyincInstallationNotice,
          AgentEmailContent,
          CreateNoteContent
        },
        mounted(){
          this.emitter.on("show-view-details", Data => {
            this[Data.displaySegment] = true;
            this.viewmodecontent = false;
              });
              this.emitter.on("hide-view-details", Data => {
                  this[Data.displaySegment] = false;
                  this.viewmodecontent = true;
              });
        },
        data: function(){
            return{
              showBCC : true,
              date: new Date(),
              timezone: '',
              projectcompleted : false,
              createnotecontent : false,
              agentemailcontent : false,
            }
        },
          methods: {
              showagentemailcontent1(segment){
              this.agentemailcontent = true;
              this.createnotecontent = false;
              },
              showcreatenotecontent1(segment){
              this.createnotecontent = true;
              this.agentemailcontent = false;
              }

          }
      }
  </script>