<template>   <vue-excel-editor v-model="jsondata" filter-row />
    <div class="expandedrowcontent box-bg-color" v-if="showDetails">
      <div class="box box-info with-border">
        <div class="box-body table-responsive">
           <div class="nav-tabs-custom color-tab-icon">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a href="#tab_messagedetail" class="nav-link active" id="home" data-bs-toggle="tab" data-bs-target="#home-tab" type="button" role="tab" aria-controls="home" aria-selected="true">
                    <i class="fa fa-navicon orange"></i>
                    <span>Message Details</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#tab_history" class="nav-link" id="history" data-bs-toggle="tab" data-bs-target="#history-tab" type="button" role="tab" aria-controls="history" aria-selected="false" data-toggle="tab">
                    <i class="fa fa-history green"></i>
                    <span>History</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#tab_contactdetail" class="nav-link" id="contact" data-bs-toggle="tab" data-bs-target="#contact-tab" type="button" role="tab" aria-controls="home" aria-selected="false" data-toggle="tab">
                    <i class="fa fa-ellipsis-h blue"></i>
                    <span>Contact Details</span>
                  </a>
                </li>
              </ul>
              <!--Tab content start-->
                <div class="tab-content box-bg-color" >
                  <!-- Details tab content -->
                    <div class="tab-pane active" id="home-tab">


                       <!--simple messages end-->
                       <div class="row" v-if="assign">
                          <div class="col-md-12 form-action">
                            <p>Select the Sales Representative for the customer using the dropdown menu, and then click <strong>Assign to Sales Representative</strong> to assign this request.</p>
                            <div class="form-group">
                                <button class="btn btn-primary" type="button"><i class="fa  fa-check-square-o"></i> Assign to Sales Representative </button>
                                <button class="btn btn-primary" type="button"><i class="fa fa-save"></i> Save Draft </button>
                                <button class="btn btn-link" type="button">Show Standard Response Form </button>
                            </div>
                            <GoleyincAssignDetail></GoleyincAssignDetail>
                          </div>
                        </div>  
                      <!--simple message end-->



                      <!--simple messages unschedule visit-->
                        <div class="row" v-if="message">
                          <div class="col-md-12 form-action">
                            <p>Use this form to schedule the estimation visit. The schedule visit button will send the customer a notification with details about the appointment. Use the <strong>Agent Email</strong> and <strong>Note</strong> button to communicate with customer.</p>
                            <div v-if="!createnotecontent && !agentemailcontent">
                              <div class="form-group">
                                  <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to James',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="showagentemailcontent('agentemailcontent')">
                                      <i class="fa fa-envelope"></i>
                                      Agent email
                                    </button> 
                                    <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to James',theme: {placement: 'bottom', }}"  v-on:click="showcreatenotecontent('createnotecontent')">
                                      <i class="fa fa-pencil-square-o"></i>
                                      Note
                                    </button>
                                  <button class="btn btn-primary" type="button"><i class="fa fa-calendar-check-o "></i> Schedule Visit </button>
                                  <button class="btn btn-primary" type="button"><i class="fa fa-save"></i> Save Draft </button>
                                  <button class="btn btn-link" type="button">Show Standard Response Form </button>
                              </div>
                            </div>
                            <GoleyincVisitDetail v-if="!createnotecontent && !agentemailcontent"></GoleyincVisitDetail>
                            <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
                            <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
                          </div>
                        </div>  
                      <!--simple messages unschedule visit end-->
                      
                        
                      <!--scheduled visits message end-->
                         <div class="row" v-if="quote">
                           <div class="col-md-12 form-horizontal">
                            <p> Use this form to send reminders about the upcoming visit. Use the Agent Email and Note buttons to correspond with the customer. When the visit is completed, click the <strong>Visit Completed</strong> button.</p>
                              <div class="row">
                                <div class="col-md-12" v-if="!createnotecontent && !agentemailcontent">
                                  <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to James',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="showagentemailcontent('agentemailcontent')">
                                      <i class="fa fa-envelope"></i>
                                      Agent email
                                    </button> 
                                    <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to James',theme: {placement: 'bottom', }}"  v-on:click="showcreatenotecontent('createnotecontent')">
                                      <i class="fa fa-pencil-square-o"></i>
                                      Note
                                    </button>
                                  <!--<button type="button" class="btn btn-primary  margin-r-5"><i class="fa fa-send"></i> Send Email Reminder</button>
                                  <button type="button" class="btn btn-primary margin-r-5"><i class="fa fa-send"></i> Send SMS Reminder</button>-->
                                 

                                  <div class="btn-group margin-r-5" uib-dropdown dropdown-append-to-body auto-close="outsideClick">
                                    <button class="btn  btn-danger margin-r-5" v-tooltip="{text: 'Reassign',theme: {placement: 'bottom', }}" v-on:click=" reassignBtn = !reassignBtn ">
                                      <i class="fa fa-mail-forward"></i>
                                      Reassign
                                     </button>
                                      <ul class="dropdown-menu dropdown-menu-btn-option" v-bind:class = "reassignBtn?'show':''" v-if="reassignBtn" v-click-outside="closeEvent">
                                        <li>
                                          <Multiselect
                                              v-model="value"
                                              placeholder="Select or search for an agent in the list"
                                              label="name"
                                              :searchable="true"
                                              :options="[
                                                { value: 'alek packard', name: 'Alek Packard',email:'alek.packard@example.com',status:'',disabled: false ,level:'1' },
                                                { value: 'jacob smith', name: 'Jacob Smith',email:'jacob.smith@example.com',status:'',disabled: false ,level:'1' },
                                              ]"
                                            >
                                              <template v-slot:singlelabel="{ value }">
                                                <div class="multiselect-single-label">
                                                    {{ value.name }}
                                                </div>
                                              </template>

                                              <template v-slot:option="{ option }">
                                                <div>
                                                  {{ option.name }} <span v-if=" option.status == 'notavailable'"><i class="fa  fa-ban text-red"   data-bs-toggle="tooltip" data-bs-placement="bottom"  title="This agent is not available because they  are logged out and have the Empty Inbox setting"></i></span>
                                                  <span v-if=" option.status == 'logout'"><i class="fa fa-fw fa-sign-out text-red" data-bs-toggle="tooltip" data-bs-placement="bottom" title="logged out agent"></i></span>
                                                  <br/> {{ option.email}}
                                                </div>
                                              </template>
                                            </Multiselect>
                                        </li>
                                      </ul>  
                                </div>
                                  <button class="btn  btn-danger margin-r-5" v-tooltip="{text: 'Send Quotes',theme: {placement: 'bottom', }}"  v-on:click="showcreatenotecontent('sendQuote')">
                                      <i class="fa fa-send"></i>
                                      Send Quote
                                  </button>

                                  <button class="btn btn-primary margin-r-5" title="Save Draft"><i class="fa fa-save"></i> Save Draft </button>
                                 <button class="btn btn-link" type="button"> Show Standard Response Form </button></div>
                              </div>
                             <GoleyincQuoteResponseBody v-if="!createnotecontent && !agentemailcontent"></GoleyincQuoteResponseBody>
                             <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
                             <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
                           </div>
                        </div>
                      <!--scheduled visits message end-->
                      <!--completed visit message start-->
                      <div class="row" v-if="completedvisit">
                          <div class="col-md-12 form-action">
                            <p>Use this form to send the quote(in PDF form) to the customer. The customer will reply with their acceptance or call Goley with their approval. Use the <strong>Agent Email</strong> and <strong>Note</strong> buttons to correspond with the customer.</p>
                            <div v-if="!createnotecontent && !agentemailcontent">
                              <div class="form-group">
                                  <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to James',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="showagentemailcontent('agentemailcontent')">
                                      <i class="fa fa-envelope"></i>
                                      Agent email
                                    </button> 
                                    <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to James',theme: {placement: 'bottom', }}"  v-on:click="showcreatenotecontent('createnotecontent')">
                                      <i class="fa fa-pencil-square-o"></i>
                                      Note
                                    </button>
                                  <button class="btn btn-primary" type="button"><i class="fa fa-send "></i> Send Quote </button>
                                
                                  <button class="btn btn-primary" type="button"><i class="fa fa-save"></i> Save Draft </button>
                                  <button class="btn btn-link" type="button">Show Standard Response Form </button>
                              </div>
                            </div>
                            <GoleyincCompletedVisitDetail v-if="!createnotecontent && !agentemailcontent"></GoleyincCompletedVisitDetail>
                            <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
                            <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
                          </div>
                        </div>  
                      <!--completed visit message end-->
                      
                      <!--Open quote messages start-->
                        <div class="row" v-if="openquote">
                           <div class="col-md-12 form-horizontal">
                            <p> This quote is pending customer acceptance. Use the <strong>Agent Email</strong> and <strong>Note</strong> buttons to send follow up emails or write call notes.</p> 
                             <GoleyOpenQuoteResponseBody></GoleyOpenQuoteResponseBody>
                           </div>
                        </div>
                      <!--open quote message end-->
                      

                       <!--waiting for payment start-->
                       <div class="row" v-if="waitingpayment">
                           <div class="col-md-12 form-horizontal">
                            <p> This quote is pending customer payment. Use the Agent Email and Note buttons to send follow up emails or write call notes. If the customer is unwilling to make payment and will not move forward, use the <strong>Reject Quote</strong> button to close the project. After payment is collected, click the <strong>Payment Collected</strong> button.</p> 
                             <GoleywaitingPaymentResponseBody></GoleywaitingPaymentResponseBody>
                           </div>
                        </div>
                      <!--waiting for payment message end-->

                       <!--unschedule install messages start-->
                       <div class="row" v-if="installation">
                          <div class="col-md-12 form-action">
                            <p>Use this form to schedule the installation at customer site. The Schedule install button will send the customer notification with details about the appointment. Use the <strong>Agent Email</strong> and <strong>Note</strong> button to communicate with the customer.</p>
                            <div class="form-group" v-if="!createnotecontent && !agentemailcontent">
                                <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to James',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="showagentemailcontent('agentemailcontent')">
                                      <i class="fa fa-envelope"></i>
                                      Agent email
                                    </button> 
                                    <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to James',theme: {placement: 'bottom', }}"  v-on:click="showcreatenotecontent('createnotecontent')">
                                      <i class="fa fa-pencil-square-o"></i>
                                      Note
                                    </button>
                                <button class="btn btn-primary" type="button"><i class="fa fa-calendar-check-o "></i> Schedule Install </button>
                                <button class="btn btn-primary" type="button"><i class="fa fa-save"></i> Save Draft </button>
                                <button class="btn btn-link" type="button">Show Standard Response Form </button>
                            </div>
                            <GoleyincVisitInstallDetail v-if="!createnotecontent && !agentemailcontent"></GoleyincVisitInstallDetail>
                            <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
                            <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
                          </div>
                        </div>  
                      <!--unschedule install messages end-->
                     
                       

                    
                      <!--Scheduled isntall messages start-->
                      <div class="row" v-if="scheduledinstallation">
                        <div class="col-md-12 form-action">
                            <p> Use this form to send install reminders and mark the project as completed. Use the <strong>Agent Email</strong> and <strong>Notes</strong> button for other correspondence with the customer.</p>
                            <GoleyScheduledInstallDetail></GoleyScheduledInstallDetail>
                            
                          </div>
                        </div>
                      <!--Scheduled isntall messages end-->

                    <!--completed install messages start-->
                      <div class="row" v-if="completedinstall">
                        <div class="col-md-12 form-action">
                            <p>Use this form to send overall completed project details and pictures.</p>
                            <GoleyCompletedInstall></GoleyCompletedInstall>
                          </div>
                        </div>
                    <!--completed install messages start-->
                    <div class="row" v-if="completedaccepted">
                        <div class="col-md-12 form-action">
                            <p>This project is now completed. Take the appropriate business development steps and then resolve the project.</p>
                            <GoleyCompletedAccepted></GoleyCompletedAccepted>
                          </div>
                        </div>

                        <div class="row" v-if="completeddeclined">
                          <div class="col-md-12 form-action">
                              <p>This project is now completed. Take the appropriate business developement steps and then resolve the project.</p>
                              <GoleyCompletedDeclined></GoleyCompletedDeclined>
                            </div>
                        </div>
                    </div>

                 
                    
                  <!--Details tab content-->
  
                  <!--History tab content-->
                    <div class="tab-pane" id="history-tab">
                      <HistoryTabContent></HistoryTabContent>
                    </div>
                  <!--History tab content-->
  
                  <!--Contact information-->
                    <div class="tab-pane" id="contact-tab">
                       <ContactInformation></ContactInformation>
                    </div>
                  <!--Contact information-->
                </div>
              <!--Tab content end-->
           </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ActionModeButtons from '../components/ActionModeButtons.vue';
  import GoleyincAssignDetail from '../components/GoleyincAssignDetail.vue';
  import GoleyincVisitDetail from '../components/GoleyincVisitDetail.vue';
  import GoleyincVisitInstallDetail from '../components/GoleyincVisitInstallDetail.vue';
  import GoleyScheduledInstallDetail from '../components/GoleyScheduledInstallDetail.vue';
  import GoleyOpenQuoteResponseBody from '../components/GoleyOpenQuoteResponseBody.vue';
  import GoleywaitingPaymentResponseBody from '../components/GoleywaitingPaymentResponseBody.vue';
  import GoleyincCompletedVisitDetail from '../components/GoleyincCompletedVisitDetail.vue';
  import GoleyCompletedInstall from '../components/GoleyCompletedInstall.vue';
  import GoleyCompletedAccepted from '../components/GoleyCompletedAccepted.vue';
  import GoleyCompletedDeclined from '../components/GoleyCompletedDeclined.vue';
  
  import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
  
  import QuoteButtons from '../components/QuoteButtons.vue';
  import GoleyincQuoteResponseBody from '../components/GoleyincQuoteResponseBody.vue';
  import QuoteQuestionDetailsTab from '../components/QuoteQuestionDetailsTab.vue';
  import ContactInformation from '../components/ContactInformation.vue';
  
  import DraftBodyTabs from '../components/DraftBodyTabs.vue';
  
  import HistoryTabContent from '../components/HistoryTabContent.vue';
  import AgentEmailContent from '../components/AgentEmailContent.vue';
  import CreateNoteContent from '../components/CreateNoteContent.vue';
  import VueExcelEditor from 'vue3-excel-editor'
  import Multiselect from '@vueform/multiselect'

  export default {
    name: 'InboxMessageDetailComponents',
     components: {
        Multiselect,
        ActionModeButtons,
        GoleyincAssignDetail,
        GoleyincVisitDetail,
        GoleyincVisitInstallDetail,
        VariableAndStockResponses,
        QuoteButtons,
        GoleyincQuoteResponseBody,
        QuoteQuestionDetailsTab,
        ContactInformation,
        DraftBodyTabs,
        HistoryTabContent,
        GoleyScheduledInstallDetail,
        GoleyOpenQuoteResponseBody,
        CreateNoteContent,
        AgentEmailContent,
        GoleywaitingPaymentResponseBody,
        GoleyincCompletedVisitDetail,
        GoleyCompletedInstall,
        GoleyCompletedAccepted,
        GoleyCompletedDeclined
    },
    data: function(){
        return{
         quote:true,
         message:false,
         installation:false,
         scheduledinstallation:false,
         showDetails :false,
         assign: false,
         createnotecontent : false,
         agentemailcontent : false,
         reassignBtn : false
        
        }
    },
    mounted() { 
      this.emitter.on("show-view-details", Data => {
              this[Data.displaySegment] = true;
              this.viewmodecontent = false;
      });
      this.emitter.on("hide-view-details", Data => {
        this[Data.displaySegment] = false;
        this.viewmodecontent = true;
      });
      this.emitter.on("show-message-details", Data => {
        this.showDetails = Data.showDetails;

        if(Data.displayType != 'quote' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment'  && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall'  && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined' ){
            this.quote = false;
            this.message = true;
            this.installation = false; 
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }else if(Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall'  && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = true;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall'  && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = true;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall'  && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  true;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall'  && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = true;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }
        else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall' && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = true;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }
        else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall' && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = true;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }
        else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedinstall' && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = true;
            this.completedinstall = false;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }
        else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedaccepted' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = true;
            this.completedaccepted = false;
            this.completeddeclined = false; 
        }
        else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall' && Data.displayType != 'completeddeclined'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = true;  
            this.completeddeclined = false; 
        }else if(Data.displayType != 'quote' && Data.displayType != 'others' && Data.displayType != 'installation' && Data.displayType != 'assign' && Data.displayType != 'scheduledinstallation' && Data.displayType != 'openquote' && Data.displayType != 'waitingpayment' && Data.displayType != 'completedvisit' && Data.displayType != 'completedinstall' && Data.displayType != 'completedaccepted'){
            this.quote = false;
            this.message = false;
            this.installation = false;
            this.assign  =  false;
            this.scheduledinstallation = false;
            this.openquote = false;
            this.waitingpayment = false;
            this.completedvisit = false;
            this.completedinstall = false;
            this.completedaccepted = false;   
            this.completeddeclined = true;
        }


        
      });
    },
    methods: {
        showagentemailcontent(segment){
          this.agentemailcontent = true;
          this.createnotecontent = false;
        },
        showcreatenotecontent(segment){
          this.createnotecontent = true;
          this.agentemailcontent = false;
        }

    }
   
  }
  </script>