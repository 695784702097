<template>
    <div class="form-group message-body-container">
<!-- NAME: 1 COLUMN --><center><!-- NAME: 1 COLUMN --><!-- NAME: 1 COLUMN -->
    <table style="background: #f1f1f1; border: 0px solid #cccccc; color: #666666; width: 100%;" cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr>
<td>&nbsp;</td>
<td style="width: 680px;" width="680"><center>
<table style="background: #ffffff; border: 0px solid #cccccc; color: #666666; width: 680px; height: 797.604px;" cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr style="height: 115.469px;">
<td style="border-color: #ffffff; height: 115.469px;">
<table width="100%" cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr>
<td style="border-color: #ffffff; padding-left: 30px;"><img alt="logo" src="https://www.iservice.info/emailer_assets/goleyinc/emailer-assets/goleyLogo.jpg" style="width: 120px; height: 107px;" width="120" height="107" /></td>
<td style="padding-right: 50px;" align="right">
<table cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr>
<td style="border-color: #ffffff; text-align: right;" align="right">
<p style="color: #000000; font-size: 17px; margin: 0px; padding: 0px;"><strong>618-286-3355</strong></p>
<a href="https://goleyinc.iservicecrm.com/f/vueapp-customerportal-tabs"> Online Service Center</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr style="height: 19.5833px;">
<td style="border-color: #ffffff; height: 19.5833px;">&nbsp;</td>
</tr>
<tr style="height: 19.5833px;">
<td style="border-color: #ffffff; padding-left: 50px; background: #dc2d26; height: 19.5833px;">&nbsp;</td>
</tr>
<tr style="height: 19.5833px;">
<td style="border-color: #ffffff; height: 19.5833px;">&nbsp;</td>
</tr>
<tr style="height: 232.222px;">
<td style="border-color: #ffffff; vertical-align: top; height: 232.222px;">
<table cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr>
<td style="border-color: #ffffff; width: 30px;">&nbsp;</td>
<td>
<table cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr>
<td style="width: 150px; text-align: center; vertical-align: middle;"><img src="blob:https://goleyinc.iservicecrm.com/a7784c55-34c5-48c2-908d-08e75699bcad" /></td>
<td>&nbsp; &nbsp; &nbsp;</td>
<td>
<h2>Thanks for requesting a quote.</h2>
<br /><span style="font-family: Verdana; font-size: 14px; line-height: 24px;">We've received your request for an quoute (see below for details) and will contact you to setup a date and time that's convenient for you to show us the property. You should hear from our customer success team within the next 48 hours. Feel free to reply to this message if you would like to tell us more or have any questions.<br /></span></td>
</tr>
</tbody>
</table>
</td>
<td style="width: 30px;">&nbsp;</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr style="height: 19.5833px;">
<td style="border-color: #ffffff; height: 19.5833px;">&nbsp;</td>
</tr>
<tr style="height: 19.5833px;">
<td style="border-color: #ffffff; height: 19.5833px;">&nbsp;</td>
</tr>
<tr style="height: 312.83px;">
<td style="height: 312.83px;">
<table style="width: 100%;" cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr>
<td style="width: 30px;">&nbsp;</td>
<td style="background-color: #333; height: 32px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #ffffff;"><strong>Details about the quote you requested</strong>:</span></td>
<td style="width: 30px;">&nbsp;</td>
</tr>
<tr>
<td style="width: 30px;">&nbsp;</td>
<td>&nbsp;</td>
<td style="width: 30px;">&nbsp;</td>
</tr>
<tr>
<td style="width: 30px;">&nbsp;</td>
<td>
<table style="width: 100%; height: 58.75px;" cellspacing="0" cellpadding="0" border="0">
<tbody>
<tr style="height: 58.75px;">
<td style="width: 3.43013%; height: 58.75px;"></td>
<td style="width: 93.1034%; height: 58.75px;"><strong><br />
    Job Number:</strong>&nbsp; 000123<br />
    <strong>Quote Price: </strong>$2000<br />
    <strong>Job Completion Time :</strong> 2 Days from start date<br />
  <br /><br /><br /><br /><br /><br /><br /></td>
<td style="width: 3.43013%; height: 58.75px;">&nbsp;</td>
</tr>
</tbody>
</table>
</td>
<td style="width: 30px;">&nbsp;</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr style="height: 19.5833px;">
<td style="border-color: #ffffff; height: 19.5833px;">&nbsp;</td>
</tr>
<tr style="height: 19.5833px;">
<td style="border-color: #ffffff; height: 19.5833px;">&nbsp;</td>
</tr>
</tbody>
</table>
</center></td>
<td>&nbsp;</td>
</tr>
</tbody>
</table>
</center>
    </div>
  </template>
  <script>
    export default {
    name: 'DetailsandattachmentsTabContentComponents',
     components: {
  
    },
    data: function(){
       
    }
  
  }
  </script>