<template>
    
  <div class="nav-tabs-custom mtop-15">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
              <a class="nav-link active" v-bind:id="'tab4'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_4_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-fw fa-envelope-o"></i> <span>Quote Details (Estimator)</span></a>
          </li>
          
          <li class="nav-item" role="presentation">
              <a class="nav-link" v-bind:id="'tab2'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_2_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'Preview Reminder',theme: {placement: 'bottom', }}"><i class="fa fa-eye"></i> <span>Preview Email Reminder</span></a>
          </li>
          <li class="nav-item" role="presentation">
              <a class="nav-link " v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-eye"></i> <span>Preview SMS Reminder</span></a>
          </li>

          <li class="nav-item" role="presentation">
              <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'Interaction Properties',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span></a>
          </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane show active" v-bind:id="'tab_4_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
              <!--Standard Response start-->
              <div class="row">
             
                <div class="col-md-8 form-horizontal">
                    <div class="form-group">
                      <label class="col-md-2 control-label text-left">Reassign</label>
                      <div class="col-md-10">
                        <Multiselect
                        v-model="value"
                        placeholder="Select or search for an agent in the list"
                        label="name"
                        :searchable="true"
                        :options="[
                          { value: 'alek packard', name: 'Alek Packard',email:'alek.packard@example.com',status:'',disabled: false ,level:'1' },
                          { value: 'jacob smith', name: 'Jacob Smith',email:'jacob.smith@example.com',status:'',disabled: false ,level:'1' },
                         ]"
                      >
                        <template v-slot:singlelabel="{ value }">
                          <div class="multiselect-single-label">
                              {{ value.name }}
                          </div>
                        </template>

                        <template v-slot:option="{ option }">
                          <div>
                            {{ option.name }} <span v-if=" option.status == 'notavailable'"><i class="fa  fa-ban text-red"   data-bs-toggle="tooltip" data-bs-placement="bottom"  title="This agent is not available because they  are logged out and have the Empty Inbox setting"></i></span>
                            <span v-if=" option.status == 'logout'"><i class="fa fa-fw fa-sign-out text-red" data-bs-toggle="tooltip" data-bs-placement="bottom" title="logged out agent"></i></span>
                            <br/> {{ option.email}}
                          </div>
                        </template>
                      </Multiselect>
                      </div>
                    </div>
                </div>  
              </div>
                  <!--Standard Response End-->
              <div class="row">
                <div class="col-md-12 form-horizontal">
                    <div class="box box-primary">
                      <div class="estimator-row"> 
                        
                        						
                        <div class="p-datatable p-component p-datatable-responsive-scroll">
                          <div class="p-datatable-wrapper table-responsive">
                              <table class="p-datatable-table editable-cells-table">
                                <thead class="p-datatable-thead">
                                  <tr>
                                    <th></th>
                                    <th>Option</th>
                                    <th>Phase</th>
                                    <th>Work Area</th>
                                    <th>Item (Material)</th>
                                    <th>Quantity</th>
                                    <th>Extended Price</th>
                                    <th>Option Discount</th>
                                    <th>Package Only?</th>
                                    <th>Customer Notes</th>
                                    <th>Crew Notes(Private)</th>
                                    </tr>
                                </thead>
                                <thead class="p-datatable-tbody">
                                  <tr>
                                    <td><button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button></td>
                                    <td>Base</td>
                                    <td>Demo</td>
                                    <td>Attic</td>
                                    <td>Standard Response</td>
                                    <td>1</td>
                                    <td>$2000.00</td>
                                    <td>$100.00</td>
                                    <td><input type="checkbox"></td>
                                    <td>These are notes for the customer. </td>
                                    <td>Watch for the xyz!</td>
                                    </tr>
                                    <tr>
                                      <td><button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button></td>
                                      <td>Option1</td>
                                      <td> <select class="p-selecttext">
                                           <option>Demo</option>
                                        </select></td>
                                      <td>Attic</td>
                                      <td>Standard Demo</td>
                                      <td>1</td>
                                      <td>$2000.00</td>
                                      <td>$100.00</td>
                                      <td><input type="checkbox"></td>
                                      <td>These are notes for the customer. </td>
                                      <td>Watch for the xyz!</td>
                                    </tr>
                                    <tr>
                                      <td><button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button></td>
                                      <td>
                                       
                                        <input class="p-inputtext" type="text" value="Option2">
                                      
                                      </td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td><input type="checkbox"></td>
                                      <td>&nbsp; </td>
                                      <td>&nbsp;</td>
                                    </tr>
                                </thead>
                              </table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>  
              </div>
              <div class="row">
                <div class="col-md-9 form-horizontal">
                    <div class="box box-primary">
                        <table class="table table-striped">
                          <thead>
                                    <tr>
                                    <th>Option</th>
                                    <th>Work Area</th>
                                    <th>Item (Material)</th>
                                    <th>Price</th>
                                    <th>Option Discount</th>
                                    <th>Discounted Option Total</th>
                                    <th>Comments</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                    <td>Base</td>
                                    <td>Attic</td>
                                    <td>Blow insul</td>
                                    <td>$2976.00</td>
                                    <td>$350.00</td>
                                    <td>$2626.00</td>
                                    <td>This are notes for the customer, This is good del.</td>
                                    </tr>
                                    <tr>
                                    <td>Option1</td>
                                    <td>Garrage</td>
                                    <td>Blow insul</td>
                                    <td>$400.00</td>
                                    <td>$50.00</td>
                                    <td>$350.00</td>
                                    <td>This is a good time to do the garage since we are already in the attic</td>
                                    </tr>
                                    <tr>
                                      <td>Option2</td>
                                      <td>Crawl Space</td>
                                      <td>Blow insul</td>
                                      <td>$200.00</td>
                                      <td>$25.00</td>
                                      <td>$175.00</td>
                                      <td>This would be quick for us to get done.</td>
                                    </tr>
                                    <tr style="border-top:2px solid #000">
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td><strong>$250.00</strong></td>
                                      <td><strong>Total Before Discount</strong></td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td><strong>$3,151.00</strong></td>
                                      <td><strong>Package Discount</strong></td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td><strong>$2,901.00</strong></td>
                                      <td><strong>Package Discount</strong></td>
                                    </tr>
                                  </tbody>
                            
                        </table>
                     
                    </div>
                </div>  
              </div>

              <div class="row">
                <div class="col-md-12 form-horizontal">
                    <div class="box box-primary">
                      <center>
                <!-- NAME: 1 COLUMN --><!-- NAME: 1 COLUMN -->
                <table style="background: #f1f1f1; border: 0px solid #cccccc; color: #666666; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                      <tr>
                          <td>&nbsp;</td>
                          <td style="width: 680px;" width="680">
                            <center>
                                <table style="background: #ffffff; border: 0px solid #cccccc; color: #666666; width: 680px; height: 816.736px;" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <tr style="height: 115.469px;">
                                        <td style="border-color: #ffffff; height: 115.469px;">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                              <tbody>
                                                  <tr>
                                                    <td style="border-color: #ffffff; padding-left: 30px;"><img alt="logo" src="https://www.iservice.info/emailer_assets/goleyinc/emailer-assets/goleyLogo.jpg" style="width: 120px; height: 107px;" width="120" height="107" /></td>
                                                    <td style="padding-right: 50px;" align="right">
                                                        <table cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                <td style="border-color: #ffffff; text-align: right;" align="right">
                                                                    <p style="color: #000000; font-size: 17px; margin: 0px; padding: 0px;"><strong>618-286-3355</strong></p>
                                                                    <a href="https://goleyinc.iservicecrm.com/f/vueapp-customerportal-tabs"> Online Service Center</a>
                                                                </td>
                                                              </tr>
                                                          </tbody>
                                                        </table>
                                                    </td>
                                                  </tr>
                                              </tbody>
                                            </table>
                                        </td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; padding-left: 50px; background: #dc2d26; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 232.219px;">
                                        <td style="border-color: #ffffff; vertical-align: top; height: 232.219px;">
                                            <table cellspacing="0" cellpadding="0" border="0">
                                              <tbody>
                                                  <tr>
                                                    <td style="border-color: #ffffff; width: 30px;">&nbsp;</td>
                                                    <td>
                                                        <table cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                <td style="width: 150px; text-align: center; vertical-align: middle;"><img src="/img/estimate-img.png" /></td>
                                                                <td>&nbsp; &nbsp; &nbsp;</td>
                                                                <td>
                                                                    <h2>Your estimate is ready for review and approval.&nbsp;</h2>
                                                                    <span style="font-family: Verdana; font-size: 14px; line-height: 24px;">We've prepared an estimate for work based on our inspection of your property. <strong>You can review our offers by clicking the link below.</strong> Please Approve or Decline each offer and then confirm with your signature as requested on the form.&nbsp;<br /><br /><span style="text-decoration: underline;">https://app.iservice.info/goleyinc-workflow.html</span><br /></span>
                                                                </td>
                                                              </tr>
                                                          </tbody>
                                                        </table>
                                                    </td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                              </tbody>
                                            </table>
                                        </td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;"></td>
                                      </tr>
                                      <tr style="height: 331.891px;">
                                        <td style="height: 331.891px;">
                                            <table style="width: 100%;" cellspacing="0" cellpadding="0" border="0">
                                              <tbody>
                                                  <tr>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                    <td style="background-color: #333; height: 32px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #ffffff;"><strong>Details about the estimate you requested</strong>:</span></td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                                  <tr>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                                  <tr>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                    <td>
           
                                                      <table style="width: 100%;" cellspacing="0" cellpadding="3" border="0">
                                                            <thead>
                                                              <tr>
                                                                <th>Option</th>
                                                                <th>Work Area</th>
                                                                <th>Item (Material)</th>
                                                                <th>Price</th>
                                                                <th>Option Discount</th>
                                                                <th>Discounted Option Total</th>
                                                                <th>Comments</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                <tr>
                                                                <td>Base</td>
                                                                <td>Attic</td>
                                                                <td>Blow insul</td>
                                                                <td>$2976.00</td>
                                                                <td>$350.00</td>
                                                                <td>$2626.00</td>
                                                                <td>This are notes for the customer, This is good del.</td>
                                                                </tr>
                                                                <tr>
                                                                <td>Option1</td>
                                                                <td>Garrage</td>
                                                                <td>Blow insul</td>
                                                                <td>$400.00</td>
                                                                <td>$50.00</td>
                                                                <td>$350.00</td>
                                                                <td>This is a good time to do the garage since we are already in the attic</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>Option2</td>
                                                                  <td>Crawl Space</td>
                                                                  <td>Blow insul</td>
                                                                  <td>$200.00</td>
                                                                  <td>$25.00</td>
                                                                  <td>$175.00</td>
                                                                  <td>This would be quick for us to get done.</td>
                                                                </tr>
                                                                <tr style="border-top:2px solid #000">
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td><strong>$250.00</strong></td>
                                                                  <td><strong>Total Before Discount</strong></td>
                                                                </tr>
                                                                <tr>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td><strong>$3,151.00</strong></td>
                                                                  <td><strong>Package Discount</strong></td>
                                                                </tr>
                                                                <tr>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td><strong>$2,901.00</strong></td>
                                                                  <td><strong>Package Discount</strong></td>
                                                                </tr>
                                                            </tbody>
                                                          
                                                      </table>
                     
                                                    </td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                              </tbody>
                                            </table>
                                        </td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                  </tbody>
                                </table>
                            </center>
                          </td>
                          <td>&nbsp;</td>
                      </tr>
                    </tbody>
                </table>
                </center>

                    </div>
                </div>  
              </div>
          </div>
          <div class="tab-pane" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
              <!--Standard Response start-->
              <div class="row">
                <div class="col-md-12 mbottom-15"><button type="button" class="btn btn-primary margin-r-5"><i class="fa fa-send"></i> Send SMS Reminder</button></div>
                <div class="col-md-12">
                 <textarea class="form-control">Your estimate is ready for review and approval.</textarea>
                </div>
              </div>
                  <!--Standard Response End-->
          </div>
          <div class="tab-pane fade" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
             
            <div class="row mbottom-1555yyyy">
              <div class="col-md-12 mbottom-15">
                 <button type="button" class="btn btn-primary  margin-r-5"><i class="fa fa-send"></i> Send Email Reminder</button>
              </div>
            </div>
            <div class="message-body-container">
                 <!-- NAME: 1 COLUMN -->
                <center>
                <!-- NAME: 1 COLUMN --><!-- NAME: 1 COLUMN -->
                <table style="background: #f1f1f1; border: 0px solid #cccccc; color: #666666; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                      <tr>
                          <td>&nbsp;</td>
                          <td style="width: 680px;" width="680">
                            <center>
                                <table style="background: #ffffff; border: 0px solid #cccccc; color: #666666; width: 680px; height: 816.736px;" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <tr style="height: 115.469px;">
                                        <td style="border-color: #ffffff; height: 115.469px;">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                              <tbody>
                                                  <tr>
                                                    <td style="border-color: #ffffff; padding-left: 30px;"><img alt="logo" src="https://www.iservice.info/emailer_assets/goleyinc/emailer-assets/goleyLogo.jpg" style="width: 120px; height: 107px;" width="120" height="107" /></td>
                                                    <td style="padding-right: 50px;" align="right">
                                                        <table cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                <td style="border-color: #ffffff; text-align: right;" align="right">
                                                                    <p style="color: #000000; font-size: 17px; margin: 0px; padding: 0px;"><strong>618-286-3355</strong></p>
                                                                    <a href="https://goleyinc.iservicecrm.com/f/vueapp-customerportal-tabs"> Online Service Center</a>
                                                                </td>
                                                              </tr>
                                                          </tbody>
                                                        </table>
                                                    </td>
                                                  </tr>
                                              </tbody>
                                            </table>
                                        </td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; padding-left: 50px; background: #dc2d26; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 232.219px;">
                                        <td style="border-color: #ffffff; vertical-align: top; height: 232.219px;">
                                            <table cellspacing="0" cellpadding="0" border="0">
                                              <tbody>
                                                  <tr>
                                                    <td style="border-color: #ffffff; width: 30px;">&nbsp;</td>
                                                    <td>
                                                        <table cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                <td style="width: 150px; text-align: center; vertical-align: middle;"><img src="/img/estimate-img.png" /></td>
                                                                <td>&nbsp; &nbsp; &nbsp;</td>
                                                                <td>
                                                                    <h2>Your estimate is ready for review and approval.&nbsp;</h2>
                                                                    <span style="font-family: Verdana; font-size: 14px; line-height: 24px;">We've prepared an estimate for work based on our inspection of your property. <strong>You can review our offers by clicking the link below.</strong> Please Approve or Decline each offer and then confirm with your signature as requested on the form.&nbsp;<br /><br /><span style="text-decoration: underline;">https://app.iservice.info/goleyinc-workflow.html</span><br /></span>
                                                                </td>
                                                              </tr>
                                                          </tbody>
                                                        </table>
                                                    </td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                              </tbody>
                                            </table>
                                        </td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;"></td>
                                      </tr>
                                      <tr style="height: 331.891px;">
                                        <td style="height: 331.891px;">
                                            <table style="width: 100%;" cellspacing="0" cellpadding="0" border="0">
                                              <tbody>
                                                  <tr>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                    <td style="background-color: #333; height: 32px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #ffffff;"><strong>Details about the estimate you requested</strong>:</span></td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                                  <tr>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                                  <tr>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                    <td>
                                                        <table style="width: 100%; height: 58.75px;" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr style="height: 58.75px;">
                                                                <td style="width: 3.43013%; height: 58.75px;"></td>
                                                                <td style="width: 93.1034%; height: 58.75px;"><strong><br />Quote Number: </strong>798797<strong><br />Contact Person's Name:</strong>&nbsp; James Johnson<br /><strong>Email Address: </strong>james.johnson@example.com<br /><strong>Phone Number:</strong> 0900809809<br /><strong>Property Address:</strong> quote<br /><strong>Customer Type:</strong> Owner<br /><strong>Your Location: </strong>Texas<br /><br /></td>
                                                                <td style="width: 3.43013%; height: 58.75px;">&nbsp;</td>
                                                              </tr>
                                                          </tbody>
                                                        </table>
                                                    </td>
                                                    <td style="width: 30px;">&nbsp;</td>
                                                  </tr>
                                              </tbody>
                                            </table>
                                        </td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                      <tr style="height: 19.5938px;">
                                        <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                      </tr>
                                  </tbody>
                                </table>
                            </center>
                          </td>
                          <td>&nbsp;</td>
                      </tr>
                    </tbody>
                </table>
                </center>

              </div>
          </div>
          <div class="tab-pane show" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
              <div class="row form-vertical">
                  <div class="col-md-4">
                      <h4>Case Properties</h4>
                      <div class="form-group">
                          <label class="col-md-12 control-label text-left required">Contact Person Name <span class="required">(Required)</span></label>
                          <div class="col-12"><input type="text" class="form-control"></div>
                      </div>
                      <div class="form-group">
                          <label class="col-md-12 control-label text-left required">Email Address <span class="required">(Required)</span></label>
                          <div class="col-12"><input type="text" class="form-control"></div>
                      </div>
                      <div class="form-group">
                          <label class="col-md-12 control-label text-left">Phone Number</label>
                          <div class="col-12"><input type="text" class="form-control"></div>
                      </div>
                      <div class="form-group">
                          <label class="col-md-12 control-label text-left">Short Description</label>
                          <div class="col-12"><textarea class="form-control"></textarea></div>
                      </div>
                  </div>
                  <div class="col-md-4">
                     
                      <div class="form-group">
                          <label class="col-md-12 control-label text-left">Office Location</label>
                          <div class="col-12"><input type="text" class="form-control"></div>
                      </div>
                      <div class="form-group">
                          <label class="col-md-12 control-label text-left">Type of Service</label>
                          <div class="col-12"><input type="text" class="form-control"></div>
                      </div>
                    
                      <div class="form-group">
                          <label class="col-md-12 control-label text-left">Detail</label>
                          <div class="col-12"><textarea class="form-control" rows="3"></textarea></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <div  class="row">
      <div class="col-md-12">
          <div class="box">
              <div class="box-header with-border">
                  <h3 class="box-title head-title-padding">Original Question</h3>
              </div>
              <div class="box-body">
                 
                          <div class="message-box-header">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="subject-heading"><b>Subject : </b> Feedback provided </div>
                                  </div>
                                  <div class="col-md-12">
                                      <div class="user-container"><span class="user"><img src="/img/agent.png"><b>Agent</b></span><span>John Doe</span></div>
                                      <div class="message-info"><b>From : </b>john.doe@example.com<br><b>To : </b>alek.packard@1to1service.com<br><b>CC : </b>justin.time@1to1service.com<br></div>
                                      <div class="int-det-box pull-right">
                                          <div><b>Interaction ID : </b>4645646</div>
                                          <div><b> Segment / Topic : </b>1. Scheduled Visits</div>
                                          <div><b> Resolved By : </b></div>
                                          <div><b> Approved By : </b>&nbsp;</div>
                                          <div><b> Assigned To : </b>Adam</div>
                                          <div><b> Interaction Status : </b><small class="bg-yellow-disabled label">Pending</small></div>
                                          <div><b> Interaction Type : </b></div>
                                      </div>
                                  </div>
                              </div>
                              <div class="clearfix"></div>
                              <div class="col-md-12 mtop-15"><b><i class="fa fa-paperclip attachment-icon"></i></b><a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image1.jpg (123KB)</span></a> <a href="" class="attachments"><i class="fa fa-file-image-o"></i> <span class="filename">Image2.jpg (30kb)</span></a> <a href="" class="attachments"><i class="fa fa-file-pdf-o"></i><span class="filename"> File1.pdf (40KB)</span></a></div>
                              <div class="col-md-12 mtop-15"><div class="message-box overflow-box"><div class="message-box-body"><div class="clear"></div><div><p> Hello,<br> I'm looking for insulation. </p></div></div></div></div>
                        
                      </div>
                         
              </div>
          </div>
      </div>
  </div>

  </template>
  
  <script>
  import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
  import GoleyincVisitNotice from '../components/GoleyincVisitNotice.vue';
  import Multiselect from '@vueform/multiselect'


  import { ProductService } from '../service/ProductService?8989';
  import { ref, onMounted } from 'vue';


  const products = ref();

  const columns = ref([
    { field: 'option', header: 'Option' },
    { field: 'phase', header: 'Phase' },
    { field: 'workarea', header: 'Work Area' },
    { field: 'itemsky', header: '	Item (Material-SKU)' },
    { field: 'customerdescription', header: '	Customer Description' },
    { field: 'qty', header: 'Qty' },
    { field: 'extendedprice', header: 'Extended Price' }
  ]);

    const onCellEditComplete = (event) => {
      alert("");
    let { data, newValue, field } = event;

    switch (field) {
        case 'quantity':
        case 'price':
            if (isPositiveInteger(newValue)) data[field] = newValue;
            else event.preventDefault();
            break;

        default:
            if (newValue.trim().length > 0) data[field] = newValue;
            else event.preventDefault();
            break;
    }
      };
      const isPositiveInteger = (val) => {
          let str = String(val);

          str = str.trim();

          if (!str) {
              return false;
          }

          str = str.replace(/^0+/, '') || '0';
          var n = Math.floor(Number(str));

          return n !== Infinity && String(n) === str && n >= 0;
      };
      const formatCurrency = (value) => {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      }
      export default {
        name: 'goleyincQuoteresponseBody',
        props: ['tabOption'],
        components: {
          Multiselect,
          InteractionPropertyTab,
          GoleyincVisitNotice,
          ProductService
        },
        mounted(){
          ProductService.getProductsMini().then((data) => (products.value = data));  
        },
        data: function(){
            return{
              showBCC : true,
              date: new Date(),
              timezone: '',
              columns:[
              { field: 'option', header: 'Option' },
              { field: 'phase', header: 'Phase' },
              { field: 'workarea', header: 'Work Area' },
              { field: 'itemsky', header: '	Item (Material-SKU)' },
              { field: 'crewnote', header: 'Crew Note' },
              { field: 'customerdescription', header: '	Customer Description' },
              { field: 'qty', header: 'Qty' },
              { field: 'extendedprice', header: 'Extended Price' },
                        ],
              products : [
                {
                  option: 'Base',
                  phase: 'Initial',
                  workarea: 'Attic',
                  itemsky: 'UIui898989',
                  crewnote: 'note',
                  customerdescription: 'Blow Inc.',
                  qty: 1,
                  extendedprice: '$8989.0',
                  
                },
                {
                  option: 'Option 1',
                  phase: 'Initial',
                  workarea: 'Attic',
                  itemsky: 'UIui898989',
                  crewnote: 'note',
                  customerdescription: 'Blow Inc.',
                  qty: 1,
                  extendedprice: '$8989.0',
                },
                {
                  option: '',
                  phase: '',
                  workarea: '',
                  itemsky: '',
                  crewnote: '',
                  customerdescription: '',
                  qty: '',
                  extendedprice: '',
                }]
             
            }
        },
        methods: {
          onCellEditComplete(event){
            let { data, newValue, field } = event;
                switch (field) {
                    case 'quantity':
                    case 'price':
                        if (isPositiveInteger(newValue)) data[field] = newValue;
                        else event.preventDefault();
                        break;

                    default:
                        if (newValue.trim().length > 0) data[field] = newValue;
                        else event.preventDefault();
                        break;
                }
            }

        }
      }
  </script>

  <style>

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}
.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #ccc;
  border-width: 1px 1px 1px 1px;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
  width: 80px !important;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #343a40;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #1D4ED8;
  background: #EFF6FF;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #343a40;
}
      .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
        color: #343a40;
      }
      .p-datatable .p-sortable-column.p-highlight {
        background: #EFF6FF;
        color: #1D4ED8;
      }
      .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: #1D4ED8;
      }
      .p-datatable .p-sortable-column.p-highlight:hover {
        background: #EFF6FF;
        color: #1D4ED8;
      }
      .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
        color: #1D4ED8;
      }
      .p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #BFDBFE;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #ccc;
  border-width: 1px 1px 1px 1px;
  padding: 8px;
  width: 150px !important;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #BFDBFE;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  font-weight: 700;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid #BFDBFE;
  outline-offset: -0.15rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #EFF6FF;
  color: #1D4ED8;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #EFF6FF;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #EFF6FF;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-datatable .p-column-resizer-helper {
  background: #3B82F6;
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
  background-color: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable .p-datatable-loading-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
  border-width: 0 0 0 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #EFF6FF;
  color: #1D4ED8;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #1D4ED8;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #1D4ED8;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #dee2e6;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-selecttext, .p-inputtext{width: 165px;
background: #fff;
border: 1px solid #ccc;
padding: 4px;}
  </style>