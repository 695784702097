<template>
    <div class="form-group message-body-container">
        <!-- NAME: 1 COLUMN -->
            <center>
            <!-- NAME: 1 COLUMN --> <!-- NAME: 1 COLUMN -->
            <table style="background: #f1f1f1; border: 0px solid #cccccc; color: #666666; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td>&nbsp;</td>
                        <td style="width: 680px;" width="680">
                        <center>
                            <table style="background: #ffffff; border: 0px solid #cccccc; color: #666666; width: 680px; height: 994.001px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr style="height: 114.594px;">
                                    <td style="border-color: #ffffff; height: 114.594px;">
                                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                <td style="border-color: #ffffff; padding-left: 50px;"><img alt="logo" src="https://www.iservice.info/emailer_assets/goleyinc/emailer-assets/goleyLogo.jpg" style="width: 120px; height: 107px;" width="120" height="107" /></td>
                                                <td style="padding-right: 50px;" align="right">
                                                    <table cellspacing="0" cellpadding="0" border="0">
                                                        <tbody>
                                                            <tr>
                                                            <td style="border-color: #ffffff; text-align: right;" align="right">
                                                                <p style="color: #000000; font-size: 17px; margin: 0px; padding: 0px;"><strong>618-286-3355</strong></p>
                                                                <a href="https://goleyinc.iservicecrm.com/f/vueapp-customerportal-tabs"> Online Service Center</a>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; padding-left: 50px; background: #dc2d26; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 663.875px;">
                                    <td style="border-color: #ffffff; vertical-align: top; height: 663.875px;">
                                        <table style="width: 100.148%; height: 557.906px;" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr style="height: 144px;">
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 144px;">&nbsp;</td>
                                                <td style="width: 84.6608%; height: 144px;">
                                                    <table cellspacing="0" cellpadding="0" border="0">
                                                        <tbody>
                                                            <tr>
                                                            <td style="width: 130px; text-align: center; vertical-align: middle;"><img src="/img/installation-schedule.png" alt="Retrofit Installation" width="202" height="175" /></td>
                                                            <td>&nbsp; &nbsp; &nbsp;</td>
                                                            <td>
                                                                <h2>We've confirmed a date and time for installation.</h2>
                                                                <h3>2023-02-01 1:00 PM (CST)</h3>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 144px;">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                <td style="border-color: #ffffff; width: 30px;">&nbsp;</td>
                                                <td>
                                                    <span style="font-family: Verdana; font-size: 14px; line-height: 24px;"> <br /><strong>Dear James Johnson</strong>, </span>
                                                    <p> We've scheduled the date and time to complete your project. You'll receive a reminder prior to our visit to confirm the date and time is still good for you.
<br/><br/>
Please let us know if you have any questions or need additional information. </p>
                                                </td>
                                                <td style="width: 30px;">&nbsp;</td>
                                                </tr>
                                                <tr style="height: 32px;">
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 32px;">&nbsp;</td>
                                                <td style="background-color: #333333; height: 32px; width: 84.6608%;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="color: #ffffff;"> <b style="color: #ffffff;">Your scheduled Installation</b></span></td>
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 32px;">&nbsp;</td>
                                                </tr>
                                                <tr style="height: 19.5938px;">
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 19.5938px;">&nbsp;</td>
                                                <td style="width: 84.6608%; height: 19.5938px;">&nbsp;</td>
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 19.5938px;">&nbsp;</td>
                                                </tr>
                                                <tr style="height: 291.125px;">
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 291.125px;">&nbsp;</td>
                                                <td style="width: 84.6608%; height: 291.125px;">
                                                    <table style="width: 93.5428%; height: 215.532px;" cellspacing="0" cellpadding="2" border="0">
                                                        <tbody>
                                                            <tr style="height: 19.5938px;">
                                                            <td style="border-color: #ffffff; width: 98.9418%; height: 19.5938px;"><span><strong>Contact Name:</strong>  James Johnson </span></td>
                                                            </tr>
                                                            <tr style="height: 19.5938px;">
                                                            <td style="border-color: #ffffff; width: 98.9418%; height: 19.5938px;"><span><strong>Contact Phone Number:</strong>  5122222 </span></td>
                                                            </tr>
                                                            <tr style="height: 19.5938px;">
                                                            <td style="border-color: #ffffff; width: 98.9418%; height: 19.5938px;"><span><strong>Property Location:</strong>  7630 Mountain Ave, MA 8723</span></td>
                                                            </tr>
                                                            <tr style="height: 19.5938px;">
                                                            <td style="border-color: #ffffff; width: 98.9418%; height: 19.5938px;"><span><strong>Customer Type:</strong> &nbsp;Homeowner </span></td>
                                                            </tr>
                                                            <tr style="height: 19.5938px;">
                                                            <td style="border-color: #ffffff; width: 98.9418%; height: 19.5938px;"><span><strong>Installer Name:</strong> &nbsp;Jhon Doe </span></td>
                                                            </tr>
                                                            <tr style="height: 39.1875px;">
                                                            <td style="width: 98.9418%; height: 39.1875px;"><strong>Installation Date and Time:</strong> 2023-02-01 1:00 PM (CST) <br /><br /></td>
                                                            </tr>
                                                            <tr style="height: 19.5938px;">
                                                            <td style="border-color: #ffffff; width: 98.9418%; height: 19.5938px;"><span> <strong>Comments:&nbsp;</strong> </span></td>
                                                            </tr>
                                                           
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 291.125px;">&nbsp;</td>
                                                </tr>
                                                <tr style="height: 32px;">
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 32px;">&nbsp;</td>
                                                <td style="background-color: #333333; height: 32px; width: 84.6608%;">&nbsp; &nbsp; <span style="color: #ffffff;"> <strong>Planning for our visit</strong>: </span></td>
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 32px;">&nbsp;</td>
                                                </tr>
                                                <tr style="height: 19.5938px;">
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 19.5938px;">&nbsp;</td>
                                                <td style="width: 84.6608%; height: 19.5938px;"><span>&nbsp;</span></td>
                                                <td style="width: 7.37463%; height: 19.5938px;">&nbsp;</td>
                                                </tr>
                                                <tr style="height: 19.5938px;">
                                                <td style="border-color: #ffffff; width: 7.37463%; height: 19.5938px;">&nbsp;</td>
                                                <td style="width: 84.6608%; height: 19.5938px;"><span>We're looking forward to our visit. To prepare for your insulation professional, we suggest the following. <br /><br />- Make sure the insaller can access the space <br />- Other important details should be here <br /><br />Regards, <br />Your Goley Insulation Professionals <br /><strong>618-286-3355</strong> <br /></span></td>
                                                <td style="width: 7.37463%; height: 19.5938px;">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                    <tr style="height: 19.5938px;">
                                    <td style="border-color: #ffffff; height: 19.5938px;">&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </center>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            </center>

    </div>
  </template>
  <script>
    export default {
    name: 'DetailsandattachmentsTabContentComponents',
     components: {
  
    },
    data: function(){
       
    }
  
  }
  </script>